<template>
    <div class="container">
        <b-modal title="Update Status" id="modal" no-close-on-esc hide-footer @hidden="model={}">
            <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                    <div class="row">
                        <div class="col-md-12 mt-4">
                            <b-form-select name="status" label="status" v-model="model.status" :rules="rules.status"
                                           :options="options"></b-form-select>
                        </div>
                        <div class="col-md-12 d-none">
                            <validated-input name="id" label="id" v-model="model.id" :rules="rules.id"/>
                        </div>
                        <div class="col-md-12 mt-4">
                            <b-button type="submit" variant="success">
                                Update
                            </b-button>
                        </div>

                    </div>
                </b-form>
            </validation-observer>
        </b-modal>
        <div class="row mt-4">
            <div class="col-md-12">
                <div class="card card-pricing border-0 text-center mb-4">
                    <div class="card-body">
                        <div class="mt-3 row">
                            <div class="col-4 pl-0">
                                <b-col>
                                    <h3>Customer Name</h3>
                                    <span>{{ user[0].name }}</span>
                                </b-col>

                                <b-col class="mt-3">
                                    <h3>Email</h3>
                                    <span>{{ user[0].email }}</span>
                                </b-col>
                            </div>
                            <div class="col-4 pl-0">
                                <b-col>
                                    <h3>Contact Number</h3>
                                    <span>{{ user[0].phone }}</span>
                                </b-col>
                            </div>
                            <div class="col-4 pl-0">
                                <b-col>
                                    <h3>Address</h3>
                                    <strong><span>{{ useraddress[0].name }}</span></strong><br>
                                    <span>{{ useraddress[0].address_line_1 }}</span><br>
                                    <span>{{ useraddress[0].address_line_2 }}</span><br>
                                    <span>{{ useraddress[0].district }}, {{ useraddress[0].state }}</span><br>
                                    <span>{{ useraddress[0].country }}</span><br>
                                    <span>{{ useraddress[0].pin_code }}</span>
                                </b-col>
                            </div>

                        </div>

                    </div>
                    <div class="mt-3 row mb-4">
                        <div class="col-3 pl-0">
                            <b-col>
                                <h3>Order ID</h3>
                                <span>{{ orders[0].id }}</span>
                            </b-col>
                        </div>
                        <div class="col-3 pl-0">
                            <b-col>
                                <h3>Total Quantity</h3>
                                <span>{{ orders[0].total_quantity }}</span>
                            </b-col>
                        </div>
                        <div class="col-3 pl-0">
                            <b-col>
                                <h3>Total Amount</h3>
                                <span>{{ orders[0].total_amount }}</span>
                            </b-col>
                        </div>
                        <div class="col-3 pl-0">
                            <b-col>
                                <h3>Status</h3>
                                <span>{{ orders[0].status }}</span>
                            </b-col>
                        </div>
                    </div>
                    <div class="mt-3 row mb-4 clearfix">
                        <div class="col-9 float-left"></div>
                        <div class="col-3 float-right">
                            <b-button-group>
                                <b-button size="sm" variant="danger" @click="UpdateStatus()">Update Status</b-button>
                            </b-button-group>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ordered-items></ordered-items>

    </div>

</template>

<script>
import axios from 'secure-axios';
import urls from '@data/urls';
import OrderedItems from '@/views/SuperAdmin/Shops/Orders/OrderedItems';

export default {
    name: 'ShopDetails',
    components: { OrderedItems },
    data () {
        return {
            data: '',
            user: [],
            useraddress: [],
            orders: [],
            options: [
                { value: 'processing', text: 'processing' },
                { value: 'delivered', text: 'delivered' },
                { value: 'hold', text: 'hold' }
            ],
            model: {
                status: '',
                id: ''
            },
            rules: {
                status: {
                    required: true
                },
                id: {
                    required: true
                }
            }
        };
    },
    beforeMount () {
        this.loadData();
    },
    methods: {
        openModal () {
            this.$bvModal.show('modal');
        },
        setData (response) {
            if (response.data) {
                this.user = response.data.shopdata.user;
                this.useraddress = response.data.shopdata.user_address;
                this.orders = response.data.shopdata.order_details;
            }
        },

        async onSubmit () {
            let url;
            if (this.model.id) {
                url = urls.shopOrder.update;
            } else {
                url = urls.shopOrder.create;
            }

            const response = await axios.form(url, this.model);
            if (response.data.error === 'true') {
                this.$refs.formValidator.setErrors(response.data.errors);
            } else {
                // this.setData(response);
                this.$bvModal.hide('modal');
                this.$router.go();
                // this.$forceUpdate();
            }
        },
        async loadData () {
            const response = await axios.get(urls.shopOrder.orderdetails + '?id=' + this.$route.params.id);
            this.setData(response);
        },

        async UpdateStatus () {
            this.model = {
                id: this.$route.params.id
            };
            this.openModal();
        }

    }
};
</script>

<style scoped>

</style>
